body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

/* css styling for timecard details.js */

.timecard_ContentWrapper {
    width: 86%;
    margin: 0 auto;
    margin-top: 12px;
}

.timecard_ContentHeading {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.timecard_subHeading {
    font-size: 16px;
    color: #1b1b1b;
    margin: 0;
    padding: 0;
}


@media only screen and (max-width:600px) {
    
    .timecard_ContentWrapper {
        width: 95%;
    }

}


/* css styling for select job popup */

.timecard_dialogTitle>p {
    font-size:20px;
    font-weight:700;
    color:#000;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    margin: 0;
    padding: 0;
    line-height: 20px;
    padding: 8px 0;
}

.timecard_link {
    margin: 0;
    padding: 0;
    margin-top: 6px;
    font-weight: 400;
}

.timecard_link>a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.timecard_link>a:hover {
    color: #258fd2;
    text-decoration: underline;
}

@media only screen and (max-width:600px) {
    
    .timecard_dialogTitle>p {
        font-size: 18px;
        width: 85%;
        padding: 0;
    }



}