body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.user_innerWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 25px 25px 30px;
}

.user_heading {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    padding: 0;
    margin: 0;
    margin-bottom: 18px;
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.user_input {
    margin-bottom: 16px;
}









