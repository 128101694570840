.wrapper {
    margin-bottom:1.5%;
}

.label_content {
    color:#000;
    font-weight:400;
    font-size:14px;
    margin-top:-8px;
}

.input_content {
    width :100%;
    font-size:14px;
}

/* select option css */

.next__icon {
    max-width: 14px;
    margin-left: 1.5%;
}

.spacing {
    margin-bottom:6%;
}

.subHeading {
    color: #525252;
    font-size: 16px;
    font-weight: 400;
    margin-bottom:1.5%;
}

/* css styling for date select */

.datePara {
    color:#000;
    font-weight:400;
    font-size:10px;
    margin-bottom:0px;
}