body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.contactform_wrapper {
  display: flex;
  justify-content: center;
  width: 52%;
  margin: 0 auto;
}

.contactform_innerWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 25px;
  margin: 20px;
  background: #fff;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
}

.contactform_logo
{
  width: 50%;
  min-width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.contactform_heading {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.contactform_required {
  text-align: center;
  font-size: 16px;
  color: red;
  font-size: 16px;
  margin: 0;
  padding: 0;
  margin-bottom: 32px;
}

.contact_input {
  margin-bottom:16px;
}

.contact_label {
  width:401px;
}



@media only screen and (min-width:960px) and (max-width:1280px){
      
  .contactform_wrapper {
    width: 65%;
  }

}


@media only screen and (min-width:600px) and (max-width:960px){
      
  .contactform_wrapper {
    width: 85%;
  }

}


@media only screen and (max-width:600px){
      
  .contactform_wrapper {
    width: 100%;
  }

  .contactform_innerWrapper {
    margin: 0;
    padding: 30px 15px;
  }

  .contactform_heading {
    font-size: 22px;
  }

}