body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


/* css style for PhotoEditDialog.js file */

.photoEdit_dialogTitle>p {
    font-size:20px;
    font-weight:700;
    color:#000;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    margin: 0;
    padding: 0;
    line-height: 20px;
    padding: 8px 0;
}

.photoEdit_Content {
    width: 100%;
    padding-top: 10px;
}

.photoEdit_ImageWrapper {
    width: 100%;
    text-align: center;
    margin-top:20px;
}

.photoEdit_ImageWrapper>img {
    width: 60%;
    margin: 0 auto;
}

.photoEdit_captionWrapper {
    width: 100%;
    margin-top: 16px;
}


@media only screen and (max-width:600px) {
    
    .photoEdit_dialogTitle>p {
        font-size: 18px;
        width: 85%;
        padding: 0;
    }

    .photoEdit_Content {
        padding-top:0;
    }

    .photoEdit_ImageWrapper>img {
        width: 65%;
    }

}




/* css styling for PhotoDialog.js */

.photoDialog_title {
    padding: 16px 15px;
    margin-bottom: 10px;
}

.photoDialog_title>p {
    font-size:20px;
    font-weight:700;
    color:#000;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    margin: 0;
    padding: 0;
    line-height: 20px;
    padding: 8px 0;
}

@media only screen and (max-width:600px) {
    
    .photoDialog_title>p {
        padding: 0;
        font-size: 18px;
    }
}




/* css styling for upload.js file */

.upload_wrapper {
    width: 100%;
    display: flex;
    justify-content: center; 
    border-top: 1px solid #acacac;
}

.upload_card {
    padding: 15px 24px 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    text-align: center;
    box-shadow: none;
}

.upload_spacing {
    margin-bottom:12px;
}




/* css styling for EditMobile.js file */

.photoEdit_dialogTitle {
    border-bottom: 1px solid #acacac;
    padding: 16px 15px;
    margin-bottom: 10px;
}

.editmobile_wrapper {
    margin: 0 auto;
    overflow-x: hidden;
    padding: 15px 24px;
}

.editmobile_spacing {
    margin-top: 22px;
}

.editmobile_label {
    margin:0;
    padding: 0;
    font-weight: 500;
}

.editMobile_photos {
    width: 100%;
    height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 10px;
}





/* css styling for Header.js file */

.header_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1%;
    padding: 5px;
}

.header_center {
    text-align: center;
	font-size:15px;
}

.header_title {
    font-weight: bold;
    margin-bottom: 5px;
}

.header_backIcon {
    font-size: 21px;
    margin: 0;
    padding: 0;
}


@media only screen and (max-width:960px){
    
    .header_center {
        padding: 10px 0 15px 0;
    }

    .header_title {
        margin: 5px 0 10px;
        font-size: 20px;
    }

}




/* css styling for edit.js */

.edit_form {
    margin-top: 14px;
    margin-bottom: 16px;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;	
}

.edit_boldText {
    font-weight: 400;
	position:fixed;
	margin-top :0;
	background-color:#f1f1f1;
	padding:12px 7px;
	width:395px;
	margin-left:-10px;
	/* box-shadow : 0 0px 1px 0 rgba(0,0,0,0.1); */
    z-index: 1000;
}

.edit_boldText:nth-child(2) {
    margin-left: 20px;
	position:fixed;
	color:#000000;
}   

.edit_input {
    font-size: 15px;
    margin-top: 1%;
}

.edit_formHeading {
    font-size:20px;
    font-weight:700;
    color:#000;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    float: left;
	position:fixed;
	overflow:hidden;
	background-color:#f1f1f1;
	padding:6px;
	margin-top:-14px;
     width:396px;
	 margin-left:-10px;
	 /* box-shadow : 0 0px 1px 0 rgba(252, 218, 218, 0.1); */
	 z-index: 1;
}

.edit_topbuttons {
    position:absolute;
    right: 0;
    top: 2.5%;
	margin-left:30px;
	z-index:1;
    
}
.edit_deleteButton{
	color: red;
    padding: 0px;
    line-height: 1.5;
 
}

.edit_inputWrapper {
    margin-top:40px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	position:relative;
	z-index:-1;
	
}

.edit_inputLabel {
    margin-bottom: 0;
    font-weight: 500;
}

.fullWidth {
    width: 100%;
}

.edit_photoContainer {
    width: 100%;
    height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 10px;
}

.edit_addButton {
    text-align: center;
    margin-top: 5px;
}

.edit_clearStyle {
    clear:both;
}

.edit_Button {
    width: 100%;
    text-align: center;
    margin-top: 5px;    
}


.edit_inputWrapper_one {
    position: relative;
    margin-top: 10%;
    margin-left: -1px;
}



@media only screen and (max-width:768px) {
    
    .edit_topbuttons {
        top: 9.5%;
    }
	.edit_formHeading {
    font-size:20px;
    font-weight:700;
    color:#000;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    float: left;
	position:fixed;
	overflow:hidden;
	background-color:#ffffff;
	padding:8px;
	margin-top:-10px;
     width:254px;
	 
}


}




/* css styling for generate PDF file */

.generate_title {
    padding: 16px 15px;
    margin-bottom: 10px;
}

.generate_title>h2 {
    font-size:20px;
    font-weight:700;
    color:#000;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    margin: 0;
    padding: 0;
    line-height: 20px;
    padding: 8px 0;
}

@media only screen and (max-width:600px) {
    
    .generate_title>h2 {
        padding: 0;
        font-size: 18px;
    }
}




/* css for finalize inspection.js file */

.finalize_title {
    padding: 16px 24px;
}

.finalize_title>h2 {
    font-size:20px;
    font-weight:700;
    color:#000;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    margin: 0;
    padding: 0;
    line-height: 20px;
    padding: 8px 0;
}


@media only screen and (max-width:600px) {

    .finalize_title>h2 {
        padding: 0;
        font-size: 18px;
    }

}
