body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    /* font-family: 'Lato', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #758196;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  max-width: 100%;
  min-height: 100vh;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.gmnoprint > div > div > span
{
  padding: 8px !important;
}


body > div
{
  width: 100%;
  min-height: 100vh;
}





/* new css classes */



.extimate__replacement {
  margin-top: 6%;
  margin-bottom: 20px;
}