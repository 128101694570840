body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.report_heading {
    margin:0;
    padding:0;
    margin-Bottom:20px;
    padding-top: 4%;
    font-size: 24px;
}

.back__icon {
    max-width: 14px;
    margin-right: 14%;
    margin-top: 3%;
}



@media only screen and (max-width:640px){
    .report_heading {
        font-size: 22px;
        padding-top: 6%;
        margin-bottom: 14px;
    }
}




/* css styling for  */