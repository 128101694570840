body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


/* css styling for OpportunitiesList.js */

table {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 15px;
}


.opportunitylist_wrapper {
    width: 99%;
    margin: 0 auto;
    margin-top: 0.7%;
    overflow: visible;
    position: relative;
    z-index: 1;
}

.opportunitylist_heading {
    position: absolute;
    left: 15px;
    top: 17px;
    font-size: 24px;
    font-weight: 700;
    color: #000;
    padding: 0;
    margin: 0;
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}


@media only screen and (max-width:640px){

    .opportunitylist_wrapper {
        width: 100%;
        margin-top: 0;
    }

    .opportunitylist_heading {
        font-size: 22px;
        /* position: absolute;
        top: 15px;
        left: 14px;
        z-index: 10; */
    }
    
}

