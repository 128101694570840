/******************************************
*
*   #body container
*
*******************************************/


/* css for container */

.body__container {
    width: 100%;
    background-color: #707B8F;
    display: flex;
    align-items: center;
    justify-content: center;
}


.container__action {
    /* background: pink; */
    width: 100%;
    margin-top: 0;
    /* margin:0 auto; */
    /* margin-top: 0.7%; */
    border-radius: 4px;
}

.action__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #acacac;
    /* padding: 20px 0; */
    /* margin-bottom: 2.5%; */
    color: #000;
}

.action__back {
    position: absolute;
    left: 1.5%;
    top: 34%;
}

.action__back:hover .back__text {
    color: #000;
}

.back__icon {
    max-width: 14px;
    margin-right: 14%;
    margin-top: 6%;
}

.back__text {
    font-weight: 500;
    color: #000;
    text-decoration: none;
    display: flex;
    text-transform: uppercase;
    font-size: 13px;
}

.action__heading {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    /* line-height: 20px; */
    padding: 0;
    margin: 0;
    color: black;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.action__back {
    display: flex;
    align-items: center;
}

.action__body {
    width:97%;
    margin: 0 auto;
    /* padding-bottom: 3%; */
}

.body__heading {
    color: #2b2b2b;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
    margin-bottom: 26px;
    border-bottom: 1px solid #d8d8d8;
    
}

.body__content1 {
    display: flex;
    margin-bottom: 2.5%;
}

.content__textarea {
    width: 92%;
    height: 60px;
    padding: 5px 10px;
    margin-right: 1%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

/* .content__button>Button {
    text-transform: capitalize;   
} */

.dark__button {
    background: #535b69;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border: 0;
    padding: 10px 32px;
    border-radius: 4px;
    box-shadow: 2px 2px 5px #949494;
}

textarea{
    font-size: 15px;
}

/* table {
    font-size: 15px;
    overflow-y: hidden;
    border-bottom: 1px solid #d8d8d8;
}

table tr:hover {
    background-color: rgba(0, 0, 0, 0.07);
}

table tfoot tr {
    background-color: #fff;
    border: 0;
}

thead tr {
    font-weight: 400;
} */

/* table {
    white-space: nowrap;
} */


.icon {
    max-width: 16px;
    margin-left: 5%;
}

.common_color {
    color: #fff;
}


@media only screen and (max-width:640px){
    
   .body__content1 {
       flex-direction: column;
       align-items: flex-start;
   }

   .container__action {
        width: 100%;
        margin-top:0%;
    }

   .content__textarea {
       width: 90%;
       margin-bottom: 2%;
       /* font-size: 15px; */
   }

   .content__button {
    width: 10%;
    margin-left: 0;
    }  

    .action__body {
        width:94%;
        margin: 0 auto;
        /* padding:0 10px; */
    }
    
    .action__back {
        left:3.5%;
    }

}






