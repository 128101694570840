body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.reportleak_wrapper {
  display: flex;
  justify-content: center;
  width: 52%;
  margin: 0 auto;
}

.report_innerWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 25px;
  margin: 20px;
  background: #fff;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
}

.report_logo
{
  width: 50%;
  min-width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
} 

.report_heading {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.report_required {
    text-align: center;
    font-size: 16px;
    color: red;
    font-size: 16px;
    margin: 0;
    padding: 0;
    margin-bottom: 32px;
}

.report_input {
    margin-bottom:16px;
}

/* .report_sectionBackground {
  background: #d9f2ff;
} */

.report_fullWidth {
  width:100%;
}

.report_halfWidth {
  width:33.3%;
}

.report_spacing {
  margin-right: 3%;
  margin-bottom:16px;
}

.report_bottomSpacing {
  margin: 0;
}


@media only screen and (min-width:960px) and (max-width:1280px){
  
  .reportleak_wrapper {
    width: 65%;
  }

}

@media only screen and (min-width:600px) and (max-width:960px){
  
  .reportleak_wrapper {
    width: 85%;
  }

}

@media only screen and (max-width:600px){
  
  .reportleak_wrapper {
    width: 100%;
  }

  .report_innerWrapper {
    margin: 0;
    padding: 30px 15px;
  }

  .report_halfWidth {
    width: 100%;
  }

  .report_spacing {
    margin-right: 0;
  }

  .report_bottomSpacing {
    margin-bottom: 16px;
  }

}
